.financeiro-fullscreen {
    height: max-content;
    background-color: #FAFAFA;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
}

.financeiro-acess-full {
    margin-top: 10px;
    background-color: white;
    border-radius: 10px;
    width: 80%;
    height: 90%;
    display: flex;
    justify-content: center;
}

.financeiro-acess {
    margin-top: 30px;
    width: min-content;
    height: min-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
}

.financeiro-item {
    height: 160px;
    width: 170px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.name-main-financeiro-colonia {
    font: bold 12pt arial;
    color: gray;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: start;
    margin-top: 10px;
}

.financeiro-item:hover {
    background-color: rgba(128, 128, 128, 0.295);
}

@media(max-width:700px) {
    .financeiro-acess-full {
        width: 90%;
    }

    .financeiro-item {
        height: 130px;
        width: 140px;
    }

    .name-main-financeiro-colonia {
        font: bold 10pt arial;
    }
}

@media(max-width:500px) {
    .financeiro-acess-full {
        width: 100%;
    }
    .name-main-financeiro-colonia {
        font: bold 9pt arial;
    }
}
.full-register {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 120vh;
}

.full-register img {
    width: 85px;
    margin-bottom: 15px;
}

.register {
    width: 500px;
    border: 1px solid #666666;
    padding: 0px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.register h6 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.menu-register {
    width: 50%;
    background-color: #666666bd;
    border: 1px solid #666666;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    font: bold 13pt arial;
    color: rgba(255, 255, 255, 0.925);
}

.menu-register-container {
    display: flex;
    justify-content: center;
    align-items: start;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 20px;
}

.menu-register-item {
    width: 45%;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}

.menu-register-item label {
    font: bold 11pt arial;
    color: rgb(90, 90, 90);
    margin-left: 10px;
}

.menu-register-item input {
    width: 90%;
    height: 25px;
    font: normal 10pt arial;
    margin-bottom: 5px;
}

.register button {
    margin: 10px;
    background-color: red;
    color: white;
    border: 0;
    font: bold 9pt arial;
    border-radius: 10px;
    padding: 10px 8px;
    transition: 1s;
}

.profile-image-upload {
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
}

.input-file {
    display: none; /* Escondendo o input original */
}

.custom-file-upload {
    background-color: #6b6b6b7c;
    color: white;
    border: 1px solid #dddddd73;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    font: bold 10pt arial;
}

.custom-file-upload-clips {
    font: bold 13pt arial;
}

.image-preview-container {
    height: 80px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-preview img {
    max-width: 70px;
    max-height: 80px;
}


@media (max-width:700px) {
    .register {
        width: 400px;
    }

    .menu-register {
        width: 70%;
        font: bold 12pt arial;
    }

    .menu-register-item label {
        font: bold 10pt arial;
        color: rgb(90, 90, 90);
        margin-left: 15px;
    }

    .menu-register-item input {
        width: 95%;
        height: 25px;
        font: normal 9pt arial;
        margin-bottom: 15px;
    }

    .full-register img {
        width: 80px;
        margin-bottom: 10px;
    }

    .register h6 {
        margin-top: 15px;
        margin-bottom: 15px;
        font: bold 10pt arial;
    }
    .register button {
        margin: 5px;
        padding: 9px 7px;
        background-color: green;
    }
}

@media (max-width:450px) {
    .full-register {
        height: auto;
        padding: 30px;
    }

    .register {
        width: 300px;
    }

    .menu-register-container {
        width: 80%;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 0px;
    }

    .menu-register-item {
        width: 100%;
    }

    .menu-register {
        width: 90%;
        font: bold 10pt arial;
    }

    .menu-register-item label {
        font: bold 10pt arial;
        color: rgb(90, 90, 90);
        margin-left: 15px;
    }

    .menu-register-item input {
        width: 100%;
        height: 15px;
        width: 100%;
        font: normal 7pt arial;
        margin-bottom: 8px;
    }

    .full-register img {
        width: 70px;
        margin-bottom: 5px;
    }
    .register button {
        margin: 5px;
        padding: 9px 7px;
        background-color: green;
        font: bold 8pt arial;
    }
    .register h6 {
        margin-top: 15px;
        margin-bottom: 15px;
        font: bold 9pt arial;
    }
    .custom-file-upload {
        font-size: 11px;
    }
    .custom-file-upload-clips {
        font: bold 10pt arial;
    }
    .image-preview-container {
        height:60px;
        margin-top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .image-preview img {
        max-width: 60px;
        max-height: 70px;
    }
}

@media (max-width:350px) {
    .full-register {
        height: auto;
    }

    .register {
        width: 100%;
    }

    .menu-register-container {
        width: 90%;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 0px;
    }

    .menu-register-item {
        width: 100%;
    }

    .menu-register {
        width: 70%;
        font: bold 8pt arial;
    }

    .menu-register-item label {
        font: bold 10pt arial;
        color: rgb(90, 90, 90);
        margin-left: 15px;
    }

    .menu-register-item input {
        width: 95%;
        height: 10px;
        font: normal 9pt arial;
        margin-bottom: 10px;
    }

    .full-register img {
        width: 60px;
        margin-bottom: 5px;
    }

    .register button {
        margin: 5px;
        padding: 9px 7px;
        background-color: green;
    }
    .custom-file-upload {
        font-size: 10px;
        padding: 6px;
    }
    .custom-file-upload-clips {
        font: bold 9pt arial;
    }
}
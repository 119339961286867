/* manual.css */

.manual-container {
    text-align: center;
    padding: 20px;
    background-image: url('../assets/Imagem do WhatsApp de 2023-08-16 à(s) 19.56.51.jpg');
    color: red;
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.199);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.manual-container h1 {
    background-color: rgba(255, 255, 255, 0);
    font: bold 25pt arial;
}

.manual-title {
    font-size: 24px;
    color: red;
}

.form-container {
    margin-top: 20px;
    background-color: rgba(92, 92, 92, 0.836);
}

.form-label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: white;
    /* Cor do rótulo do campo */
}

.form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    /* Borda do campo */
    border-radius: 5px;
}

.download-button {
    background-color: red;
    /* Cor de fundo do botão */
    color: white;
    /* Cor do texto do botão */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 1s;
    margin-top: 10px;
}

.download-button:hover {
    background-color: rgb(185, 0, 0);
    /* Cor mais escura quando hover no botão */
}

@media(max-width: 450px) {
    .form-container {
        width: 95%;
        margin-top: 10px;
    }
}
/* Adicione estilos adicionais conforme necessário */

.profile-full-screen {
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 100%;
}

.profile-screen {
    background-color: #fff;
    width: 90%;
    height: 90%;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: start;
    align-items: start;
}

.edit-info-profile-area-left {
    background-color: #f8f8f8;
    width: 60%;
    margin-right: 20px;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.edit-info-profile-area-left h4 {
    color: #333;
    font-size: 18px;
    margin-bottom: 15px;
}

.edit-info-profile-area-left label {
    font-weight: bold;
    margin-bottom: 5px;
}

.edit-info-profile-area-left input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.edit-info-profile-area-left button {
    background-color: #4caf50;
    color: #fff;
    padding: 8px 12px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 15px;
}

.error-message {
    color: #ff5252;
    margin-top: 10px;
}

.success-message {
    color: #4caf50;
    margin-top: 10px;
}

.edit-info-profile-area-right {
    background-color: #f8f8f8;
    width: 35%;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.edit-info-profile-area-right input {
    display: none;
}

.img-profile-right {
    position: relative;
    width: 150px;
    /* Defina a largura desejada */
    height: 150px;
    /* Defina a altura desejada */
    border: 2px solid #9e9e9e;
    border-radius: 50%;
    overflow: hidden;
    /* Garante que o ícone não ultrapasse os limites do contêiner */
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-profile-right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    /* Ajusta a posição da imagem para o centro */
    background-color: white;
}

.img-profile-right-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    opacity: 0.5;
    /* O ícone está inicialmente invisível */
    transition: opacity 0.3s ease;
    /* Adiciona uma transição suave para a opacidade */
}

.img-profile-right:hover .img-profile-right-icon {
    opacity: 1;
    /* Torna o ícone visível quando o mouse está sobre a imagem */
}

.img-profile-right-icon-element {
    color: rgb(121, 121, 121);
    font-size: 60px;
    /* Ajuste o tamanho conforme necessário */
    margin-top: 40px;
}

.file-input-label {
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
    /* Cor escura para o rótulo */
}

.file-input {
    display: none;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
}

.update-image-button {
    background-color: #4caf50;
    color: #fff;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.update-image-button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    /* ou outro valor para torná-lo visualmente indicativo de desativado */
}


.update-image-button:hover {
    background-color: #45a049;
    /* Cor mais escura ao passar o mouse */
}

.error-message {
    color: #ff0000;
}

.success-message {
    color: #008000;
}

.default-user-icon {
    font-size: 50px;
    color: #333;
    position: relative;
}

.default-user-icon {
    font-size: 50px;
    color: #666666;
    position: relative;
    transition: 0.5s;
}

.img-profile-right:hover .default-user-icon{
    position:normal;
    color: transparent;
}


@media(max-width:900px) {
    .profile-screen {
        flex-direction: column;
        gap: 20px;
    }

    .edit-info-profile-area-left {
        width: 100%;
        order: 2;
    }

    .edit-info-profile-area-right {
        width: 100%;
        order: 1;
        padding: 10px;
    }

    .img-profile-right img {
        width: 100px;
        background-color: green;
    }
}
.modalidade-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalidade-item {
    height: 200px;
    width: 180px;
    margin: 10px;
    background-color: white;
    border-radius: 40px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid red;
}

.modalidade-item img {
    width: 70px;
    margin-bottom: 15px;
}

.modalidade-item a {
    text-decoration: none;
    font: bold 12pt arial;
    color: black;
    font-weight: 900;
}

@media(max-width:900px) {
    .modalidade-item a {
        text-decoration: none;
        font: bold 10pt arial;
        color: black;
        font-weight: 900;
    }

    .modalidade-item img {
        width: 65px;
        margin-bottom: 10px;
    }

    .modalidade-item {
        height: 180px;
        width: 160px;
    }
}

@media(max-width:600px) {
    .modalidade-item a {
        text-decoration: none;
        font: bold 9pt arial;
        color: black;
        font-weight: 900;
    }

    .modalidade-item img {
        width: 55px;
        margin-bottom: 10px;
    }

    .modalidade-item {
        height: 160px;
        width: 140px;
        padding: 15px;
        border-radius: 30px;
    }
}

@media(max-width:520px) {
    .modalidade-item a {
        text-decoration: none;
        font: bold 8pt arial;
        color: black;
        font-weight: 900;
    }

    .modalidade-item img {
        width: 45px;
        margin-bottom: 5px;
    }

    .modalidade-item {
        height: 130px;
        width: 110px;
        margin: 5px;
        border-radius: 30px;
        padding: 10px;
    }


    .modalidade-item p {
        margin-top: 10px;
    }
}

@media(max-width:400px) {
    .modalidade-item a {
        text-decoration: none;
        font: bold 8px arial;
        color: black;
        font-weight: 900;
    }

    .modalidade-item img {
        width: 35px;
        margin-bottom: 0px;
    }

    .modalidade-item {
        height: 100px;
        width: 100px;
        margin: 3px;
        border-radius: 20px;
        padding: 0px;
        border: 1px solid red;
    }

    .modalidade-item p {
        margin-top: 1px;
    }
}

@media(max-width:300px) {}
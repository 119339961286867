.pedagogico-containers {
    height: 40%;
    grid-template-columns: repeat(1, 1fr);
    padding: 10px;
    gap: 10px;
}

.container {
    background-color: white;
    width: 590px;
    padding: 15px;
    border-radius: 10px;
    border: solid 1px gray;
    border-bottom: solid 5pt red;
    margin-bottom: 10px;
    transition: max-height 1s, opacity 1s;
    cursor: pointer;
}

.container p {
    font: bold 14pt arial;
    color: rgb(65, 65, 65);
}

.container.expandido {
    max-height: 500px;
    cursor:auto;
}

.conteudo-expandido {
    display: none;
    /* Outros estilos para o conteúdo expandido, se necessário */
}

.conteudo-recolhido {
    display: block;
    /* Outros estilos para o conteúdo recolhido, se necessário */
}

.serie-item {
    border: solid 3px rgba(128, 128, 128, 0.514);
    font: bold 12pt arial;
    color: rgba(255, 0, 0, 0.856);
    padding: 10px;
    margin: 5px;
    margin-bottom: 8px;
    border-radius: 15px;
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    transition: 1s;
    cursor: pointer;
    text-decoration: none;
}

.serie-item:hover {
    background-color: rgba(88, 88, 88, 0.11);
}


@media (max-width:600px) {
    .pedagogico-containers {
        width: 100%;
        height: 800px;
    }
    .container {
        width: 90%;
    }
}
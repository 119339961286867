.sec-perguntas {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}
.sec-perguntas h1 {
    background-color: rgba(255, 255, 255, 0);
    color: red;
}

.sec-content-P {
    width: 60%;
}

.pergunta {
    margin-top: 10px;
    margin-bottom: 25px;
    padding: 10px;
    background-color: red;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pergunta:hover {
    background-color: #ff0000a0;
    /* Cor mais clara quando hover */
}

.pergunta-titulo {
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
}

.pergunta-resposta {
    margin-top: 10px;
    padding: 10px;
    background-color: rgb(255, 236, 236);
    color: red;
    /* Cor mais clara para a resposta */
    border-radius: 10px;
}

@media(max-width:800px) {
    .sec-content-P {
        width: 80%;
    }
    .pergunta-titulo {
        font-size: 14px;
    }
    .sec-perguntas h1 {
        font: bold 15pt arial;
    }
}

@media(max-width:600px) {
    .sec-content-P {
        width: 90%;
    }
    .pergunta-titulo {
        font-size: 12px;
    }
}
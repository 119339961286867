.sec-about {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 40px;
    gap: 40px;

}

.sec-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sec-item img {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-about {
    font: bold 35pt arial;
    color: red;
    margin-bottom: 12px;
}

.sec-item p {
    font-family: Arial, sans-serif;
    font: normal 22pt arial;
    color: rgb(87, 87, 87);
    text-align: justify;
}

@media(max-width:1100px) {
    .title-about {
        font: bold 27pt arial;
        margin-bottom: 12px;
    }

    .sec-item p {
        font: normal 19pt arial;
    }
}

@media(max-width:1000px) {
    .title-about {
        font: bold 26pt arial;
        margin-bottom: 12px;
    }

    .sec-item p {
        font: normal 17pt arial;
    }
}

@media(max-width:900px) {
    .sec-about {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin: 75px;
    }

    .title-about {
        font: bold 27pt arial;
        margin-bottom: 12px;
    }

    .sec-item {
        width: 100%;
    }

    .sec-item p {
        font: normal 23pt arial;
        display: flex;
        justify-content: center;
    }

    .sec-item img {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media(max-width:800px) {
    .sec-about {
        margin: 40px;
    }
}

@media(max-width:600px) {
    .sec-about {
        margin: 20px;
    }

    .title-about {
        font: bold 22pt arial;
        margin-bottom: 10px;
    }

    .sec-item p {
        font: normal 14pt arial;
        display: flex;
        justify-content: center;
    }
}

@media(max-width:400px) {
    .sec-about {
        margin: 15px;
    }

    .title-about {
        font: bold 19pt arial;
        margin-bottom: 10px;
    }

    .sec-item p {
        font: normal 13pt arial;
        display: flex;
        justify-content: center;
    }
}
.too-bar-informes {
    width: 95%;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-content: start;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.too-bar-informes button {
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 5px;
    width: 40px;
    height: 40px;
    font: bold 15pt arial;
    color: #333;
}

.informes-agenda-fullscreen {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.informes-agenda-full {
    width: 90%;
    height: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 6fr));
    gap: 10px;
}

.informes-agenda-item {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    padding: 10px;
    width: 200px;
    height: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.informes-agenda-item-img {
    width: 100%;
    height: 50%;
    overflow: hidden;
}

.informes-agenda-item-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.informes-agenda-item-content {
    width: 100%;
    height: 50%;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
}

.informes-agenda-item-content-icon {
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    font: bold 20pt arial;
    color: gray;
    flex-direction: column;
    border-right: solid 0.5px rgb(236, 236, 236);
    padding-right: 10px;
    padding-left: 10px;

}

.informes-agenda-item-content-icon p{
    font: bold 6pt arial;
}

.informes-agenda-item-content-infos {
    background-color: rgb(255, 255, 255);
    height: 100%;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-wrap: break-word;
    padding-left: 10px;
}

.informes-agenda-item-content-infos h4 {
    font: bold 7pt arial;
}

.informes-agenda-item-content-infos p {
    font: bold 6pt arial;
    color: rgb(167, 167, 167);
    word-break: break-all;
}

@media (max-width:500px) {
    .informes-agenda-full {
        width: 90%;
        height: 90%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    .informes-agenda-item {
        padding: 10px;
        width: 100%;
        height: 400px;
        gap: 10px;
    }

    .informes-agenda-item-img {
        width: 100%;
        height: 50%;
        overflow: hidden;
    }

    .informes-agenda-item-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .informes-agenda-item-content {
        width: 100%;
        height: 50%;
    }

    .informes-agenda-item-content-icon {
        font: bold 35pt arial;
    }

    .informes-agenda-item-content-infos {
        width: 60%;
    }

    .informes-agenda-item-content-infos h4 {
        font: bold 13pt arial;
    }

    .informes-agenda-item-content-infos p {
        font: bold 9pt arial;
    }

    .informes-agenda-item-content-icon p{
        font: bold 9pt arial;
    }
}
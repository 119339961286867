.atv-agenda-screen {
    width: 80%;
}



.atv-agenda-item-all {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

}

.atv-agenda-item-datas {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
    border: solid 0.5px rgba(175, 175, 175, 0.384);
    cursor: pointer;
    transition: 0.5s;
}

.atv-agenda-item-datas:hover {
    background-color: rgba(214, 214, 214, 0.548);
}

.atv-agenda-item-datas-data {
    background-color: rgba(255, 42, 42, 0.822);
    color: white;
    font: bold 12pt arial;
    padding: 5px;
    border-radius: 2px;
}

.atv-agenda-item-datas:hover .atv-agenda-item-datas-data {
    background-color: rgb(184, 0, 0);
}

.atv-agenda-item-datas-content {
    font: bold 10pt arial;
    color: rgb(97, 97, 97);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-left: 10px;
}

.atividades-agenda-list-items {
    padding: 20px;
    text-align: start;
    background-color: white;
    height: 300vh;
}

/* too bar */
.agenda-too-bar {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.agenda-too-bar select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    outline: none;
    margin-left: 10px;
}

.agenda-too-bar button {
    border: 2px solid #999999;
    background-color: white;
    width: 150px;
    height: 40px;
    color: gray;
    padding: 0px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    transition: 1s;
}

.agenda-too-bar button:hover {
    background-color: #b9b9b9ce;
}

/* Formulario */
.agenda-form-series {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: auto;
    padding: 20px;
    text-align: center;
    /* Adicionei um espaçamento interno para melhorar a aparência em telas menores */
}

.agenda-form-series label {
    margin-top: 10px;
}

.agenda-form-series select,
.agenda-form-series input,
.agenda-form-series textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
    box-sizing: border-box;
    resize: none;
    background-color: #eaeaeb;
    outline: none;
    border-radius: 5px;
}

.agenda-form-series button {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
}

.agenda-form-series button:hover {
    background-color: #45a049;
}

.atividade-agenda-select-area {
    background-color: rgba(146, 146, 146, 0.295);
    border: solid 1px rgba(128, 128, 128, 0.651);
    border-radius: 10px;
    padding: 5px;
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    /* Permite que os itens quebrem para a próxima linha conforme necessário */
    justify-content: flex-start;
    /* Alinha os itens no início do contêiner flexível */
    align-items: flex-start;
    /* Alinha os itens no início do contêiner flexível */
    width: 95%;
    height: 110px;
    gap: 3px;
    overflow-y: auto;
}

.atividade-agenda-select {
    background-color: rgba(126, 126, 126, 0.288);
    border: solid 1px gray;
    border-radius: 5px;
    padding: 3px 17px;
    font: bold 9pt arial;
    color: gray;
    margin-bottom: 2px;
}

.atividade-agenda-select:hover {
    background-color: rgba(155, 155, 155, 0.445);
}

/* View Detail */

.atividade-agenda-modal-view-detail-container {
    border: solid 1px rgba(153, 153, 153, 0.562);
    padding: 5px;
    width: 100%;
    border-radius: 5px;
}

.atividade-agenda-modal-view-detail-container .orientation {
    display: flex;
    width: 100%;
}

.atividade-agenda-modal-view-detail-container .orientation p {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    text-align: start;
}

.atividade-agenda-modal-view-detail-container .orientation div {
    width: 50%;
}

.atividade-agenda-modal-view-detail-container .title {
    font: bold 12pt arial;
    color: #333;
    text-align: start;
    padding: 10px;
}

/* Calendar  */
.custom-calendar-full {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Define a largura do calendário para 100% */
/* Define a largura do calendário para 100% */
.custom-calendar-full .react-calendar {
    width: 100% !important;
    border-radius: 10px;
    border: none;
}

.custom-calendar abbr {
    font: bold 11pt arial;
}

/* Oculta os botões de navegação do ano */
.react-calendar__navigation button.react-calendar__navigation__prev2-button,
.react-calendar__navigation button.react-calendar__navigation__next2-button,
.react-calendar__navigation .react-calendar__navigation__label:disabled {
    display: none;
}

.react-calendar__navigation button.react-calendar__navigation__prev2-button {
    color: rgb(255, 255, 255);
}

.disciplinas-list {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
    background-color: rgb(230, 230, 230);
    margin-bottom: 8px;
    padding: 10px;
    border-radius: 3px;
}

.disciplinas-list .selected-materia {
    background-color: rgb(231, 0, 0);
    color: white;
    font: bold 15px arial;
    padding: 5px;
    border-radius: 5px 10px;
    cursor: pointer;
}

.disciplinas-list .materia {
    color: #333;
    font: bold 15px arial;
    cursor: pointer;
}

.buttons-edition-agenda {
    border: solid 1px rgba(128, 128, 128, 0.705);
    border-radius: 5px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    font: bold 13pt arial;
    margin-right: 10px;
    margin-bottom: 5px;
}

/* Adicione este CSS ao seu arquivo de estilos */
.download-button {
    color: #333;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px 16px;
    line-height: 1.5;
    cursor: pointer;
}

.google-drive-style {
    background-color: #4285F4;
    color: #FFFFFF;
    border-color: #E0E0E0;
}

.google-drive-style:hover {
    background-color: #3C78D8;
}


@media only screen and (max-width: 600px) {

    .atv-agenda-item-all {
        padding-bottom: 60px;
        /* Colunas automáticas com largura mínima de 300px */

    }

    .agenda-form-series {
        max-width: 100%;
        padding: 15px;
    }

    .agenda-form-series button {
        font-size: 16px;
    }

    .atv-agenda-item-datas {
        width: 100%;
    }

    .atv-agenda-screen {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .atv-agenda-item-datas {
        width: 100%;
        padding: 5px;
        border-radius: 5px;
        margin: 0;
        margin-top: 10px;
    }

    .agenda-too-bar {
        width: 100%;
    }

    .atividade-agenda-modal-view-detail-container .orientation p {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        text-align: start;

        font: bold 8pt arial;
    }


    .custom-calendar-full {}

    .custom-calendar {}
}
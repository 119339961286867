.pedagogico-full-sceen {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pedagogico-notas {
    width: 80%;
}

.toolbarpedagogico {
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: rgba(243, 243, 243, 0.486);
    padding-left: 30px;
    padding: 5px;
}

.toolbarpedagogico button {
    background-color: white;
    border: solid 1px rgb(180, 180, 180);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    transition: 1s;
}

.toolbarpedagogico button:hover {
    background-color: rgba(216, 216, 216, 0.664);
}

.Modal-pedagogico-fundamental {
    background-color: rgba(238, 238, 238, 0.904);
    position: fixed;
    left: 300px;
    top: 120px;
    width: 800px;
    height: 500px;
    border-radius: 20px;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: start;
}

.Modal-pedagogico-fundamental form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.Modal-pedagogico-fundamental form h4 {
    margin-bottom: 20px;
}

.Modal-pedagogico-fundamental form label {
    width: 80%;
}

.Modal-pedagogico-fundamental form label p {
    margin-bottom: 0px;
    margin-top: 10px;
    margin-left: 15px;
    font: normal 12pt arial;
}

.sair-modal {
    background-color: rgb(255, 255, 255);
    width: 33px;
    height: 33px;
    font: bold 25pt arial;
    border: solid 2px rgb(167, 167, 167);
    color: rgb(109, 109, 109);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    position: fixed;
    left: 288px;
    top: 110px;
}

.adicionar-aluno-fundamental {
    background-color: red;
    border: 0;
    color: white;
    font: bold 10pt arial;
    width: fit-content;
    margin-top: 20px;
    padding: 8px;
    border-radius: 12px;
    transition: 1s;
}

.adicionar-aluno-fundamental:hover {
    background-color: rgb(182, 0, 0);
}

@media (max-width:1150px) {
    .pedagogico-full-sceen {
        padding-bottom: 20px;
    }
    .Modal-pedagogico-fundamental {
        width: 60%;
    }

    .sair-modal {
        left: 290px;
    }
}

@media (max-width:900px) {
    .Modal-pedagogico-fundamental {
        width: 80%;
        height: 450px;
        left: 100px;
    }

    .sair-modal {
        left: 95px;
    }
}

@media (max-width:600px) {
    .Modal-pedagogico-fundamental {
        width: 80%;
        left: 40px;
    }

    .sair-modal {
        left: 30px;
    }

    .Modal-pedagogico-fundamental form {
        width: 100%;
    }
}

/* listagem do aluno ew edição da nota */

.pedagogico-screen {
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.pedagogico-screen-alunos {
    overflow-y: auto;
    max-height: 400px;
    /* ou qualquer altura desejada */
    padding: 0 10px;
    /* Adicione algum espaço ao redor */
    direction: rtl;
    /* Define a direção da direita para a esquerda */
    
    border: solid 1px rgba(133, 133, 133, 0.404);
    margin-top: 10px;
}

.pedagogico-screen-alunos td{
    cursor: pointer;
    padding: 10px;
}

.selectedAluno{
    background-color: rgba(255, 0, 0, 0.212);

}

.pedagogico-screen-alunos th{
    padding: 10px;
    border:solid 1px gray;
    font: bold 12pt arial;
    color: red;
}

.pedagogico-screen-edit {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.pedagogico-screen-edit .edit-data {
    height: 50%;
    background-color: rgb(245, 245, 245);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: solid 1px rgba(133, 133, 133, 0.404);
}

.pedagogico-screen-edit .edit-data .data-right {
    width: 50%;
    height: 100%;
    display:  flex;
    justify-content: center;
    align-items: center;
}

.pedagogico-screen-edit .edit-data .data-left {
    background-color: rgb(243, 243, 243);
    width: 50%;
    height: 100%;
    font: bold 11pt arial;
    color: rgb(105, 105, 105);
    display: flex;
    flex-direction: column;
    align-items: start;
}

.pedagogico-screen-edit .edit-notas {
    height: 900px;
    background-color: #f5f2f2;
    border: solid 1px rgba(133, 133, 133, 0.404);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
}

.toolbarpedagogico-editnotas {
    background-color: rgba(231, 231, 231, 0.685);
    width: 70%;
    padding: 5px;
    margin: 8px;
    border-radius: 10px;
    display: flex;
    justify-content: start;
}
.toolbarpedagogico-editnotas button {
    background-color: orange;
    padding: 7px;
    font: bold 11pt arial;
}

.bimestre-buttons {
    width: fit-content;
    background-color: rgb(223, 223, 223);
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 15px;
}

.bimestre-buttons button {
    background-color: rgba(255, 255, 255, 0);
    border: none;
    font: bold 10pt arial;
    color: rgb(95, 95, 95);
    margin-right: 5px;
}

.bimestre-buttons .selected{
    background-color: rgba(255, 0, 0, 0.877);
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.bimestre-list-notas {
    display: flex;
    padding: 10px;
    width: 60%;
    height: 65%;
}

.bimestre-list-notas-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin: 5px;
}

.bimestre-list-notas-right p {
    background-color: gray;
    margin-bottom: 0px;
    font: bold 12pt arial;
    color: white;
    padding: 6px;
}

.bimestre-list-notas-left {
    width: 50%;
    margin: 5px;
}

.bimestre-list-notas-left p {
    background-color: gray;
    margin-bottom: 0px;
    font: bold 12pt arial;
    color: white;
    padding: 6px;
}

/* notas finais */
.list-notas-finais {
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
}
.list-notas-finais h5 {
    width: 55%;
    text-align: start;
    padding-left: 10px;
}

.notasfinais-container {
    width: 60%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: start;
}

.notasfinais-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border:solid 0.5px gray;
    margin: 1px;
}
.notasfinais-item p{
    background-color: gray;
    padding: 5px;
    font: bold 12pt arial;
    color: white;
}

@media (max-width:850px) {
    .bimestre-list-notas {
        display: flex;
        padding: 0px;
        padding-top: 5px;
        width: 95%;
        height: 65%;
    }
    .bimestre-list-notas-right p {
        font: bold 10pt arial;
    }
    .bimestre-list-notas-left p {
        font: bold 10pt arial;
    }
    .pedagogico-screen {
        height: 100%;
        flex-direction: column;
        margin-bottom: 40px;
    }
    .pedagogico-screen-alunos {
        width: 100%;
        max-height: 300px;
        order: 1;
    }
    .pedagogico-screen-edit {
        height: 70%;
        order: 2;
    }
}
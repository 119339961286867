header {
    background-color: white;
}

.sec-header-1 {
    margin-left: 30px;
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec-header-2 {
    border-bottom: 1px solid #CCCCCC;
    height: 11vh;
    display: flex;
    justify-content: center;
}

.menu {
    align-items: flex-start;
    width: 80%;
    display: flex;
    align-items: end;
    justify-content: center;
    font: bold 12pt arial;
    margin: 5px;
}

.nav-link {
    padding: 30px;
    margin-left: 40px;
    margin-bottom: 15px;
}

.nav-link a {
    color: gray;
    text-decoration: none;
    /* Remove a decoração padrão do link (sublinhado) */
    font: bold 13pt arial;
    /* Define o peso da fonte para negrito */
}

.main-button {
    width: fit-content;
    padding: 20px;
    margin-left: 100px;
}

.nav-link:hover {
    border-bottom: 2px solid gray;
}

.logo {
    height: 80px;
    width: 75px;
}

.items {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.items a {
    margin: 0;
    padding: 0;
    margin-left: 10px;
}

.items-local {
    display: flex;
    flex-direction: end;
    height: 100%;
    color: #919191;
    font: normal 12pt arial;
    margin-left: 50px;
}

.items-local p {
    height: 100%;
    display: flex;
    align-items: center;
}

.menu-icon {
    margin-bottom: 17px;
    padding: 30px;
    font-size: 24px;
    color: #333;
    cursor: pointer;
}

/* header para usuario  */

.header-area-user {
    float: left;
    margin-right: 20px;
    background-color: red;
    height: 350vw;
}

.area-user-link {
    display: block;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
    color: white;
    padding: 10px;
    font: normal 10pt arial;
    display: flex;
    border-bottom: 1px solid rgba(128, 128, 128, 0.514);
}

.link-name {
    width: 80%;
    display: none;
    align-items: center;
    justify-content: center;
}

.link-item {
    margin-left: unset;
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    font-size: 25px;

}

.link-name-show {
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: center;

}

.link-item-show {
    margin-left: unset;
    display: flex;
    justify-content: end;
    align-items: center;
    width: 20%;
}

/* seção de usuario */
.sec {

    display: flex;
    align-items: center;
    justify-content: end;
    width: 90%;

}

.user-sec {
    display: flex;
    font: normal 12pt arial;
    color: rgb(104, 104, 104);
    justify-content: center;
    align-items: center;
}

.user-sec img {
    width: 4vh;
    height: 4vh;
    border-radius: 100%;
    border: 2px solid red;
}

.user-menu {
    position: absolute;
    margin: 5px;
    /* Posicionar o menu logo abaixo da imagem de perfil */
    right: 0;
    /* Alinhar o menu à direita do perfil */
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 150px;
    /* Largura do menu */
}

.user-menu button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    padding: 5px 0;
    transition: color 0.3s ease;
}

.user-menu button:hover {
    color: #f44336;
    /* Cor vermelha ao passar o mouse */
}

.user-sec {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-name-perfil {
    margin-top: 10px;
    font: bold 11pt arial;
    color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}


.header-perfil img:hover {
    border: solid 2px rgba(255, 0, 0, 0.658);
}

.sec-header-1 img {
    width: 70px;
    height: 70px;
}

.header-perfil img {
    border: solid 2px red;
    transition: 1s;
    width: 60px;
    height: 60px;
}


@media(max-width: 950px) {
    .nav-link {
        padding: 20px;
        margin-left: 20px;
    }

    .nav-link a {
        color: gray;
        text-decoration: none;
        font: bold 13pt arial;
    }

    .main-button {
        padding: 20px;
        margin-left: 40px;
    }

    .sec-header-1 {
        height: 13vh;
        margin-left: 20px;
    }

    .logo {
        height: 70px;
        width: 65px;
    }

    .header-perfil img {
        width: 60px;
        height: 60px;
    }
}

@media(max-width: 800px) {
    .nav-link {
        padding: 20px;
        margin-left: 15px;
    }

    .main-button {
        margin-left: 20px;
    }

    .logo {
        height: 60px;
        width: 55px;
    }

    .sec-header-1 {
        height: 12vh;
        margin-left: 10px;
    }

    .sec-header-1 img {
        width: 55px;
        height: 55px;
    }

    .header-perfil img {
        width: 60px;
        height: 60px;
    }
}

@media(max-width: 750px) {
    .menu {
        width: 100%;
    }

    .nav-link {
        padding: 20px;
        margin-left: 15px;
    }

    .main-button {
        padding: 10px;
        margin-left: 50px;
    }

    .nav-link a {
        font: bold 11pt arial;
    }

    .sec-header-1 {
        height: 11vh;
    }

    .sec-header-2 {
        height: 10vh;
    }

    .user-sec {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }

    .header-name-perfil {
        margin-top: 10px;
        font: bold 8pt arial;
        color: gray;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }

    .header-perfil img {
        width: 45px;
        height: 45px;
        border: solid 1px red;
        transition: 1s;
    }

    .header-area-user-show {
        position: fixed;
        background-color: rgba(255, 0, 0, 0.897);
    }

    .header-perfil img {
        width: 50px;
        height: 50px;
    }

}

@media(max-width: 640px) {
    .nav-link {
        padding: 20px;
        margin-left: 15px;
    }

    .nav-link a {
        font: bold 10pt arial;
    }

    .main-button {
        font: normal 8pt arial;
        padding: 7px;
        margin-left: 34px;
    }

    .nav-link a {
        font: bold 10pt arial;
    }

    .sec-header-1 {
        height: 10vh;
    }

    .sec-header-2 {
        height: 9vh;
    }

    .sec-header-1 {
        height: 10vh;
        margin-left: 10px; 
    }

    .sec-header-1 img {
        width: 55px;
        height: 55px;
    }

    .header-perfil img {
        width: 40px;
        height: 40px;
    }

}

@media(max-width:500px) {
    .header-area-user {
        background-color: red;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
        width: 100%;
        height: fit-content;
        bottom: 0;
        z-index: 1000;
        /* Coloca o elemento na parte inferior */
        top: auto;
        /* Remove o posicionamento superior */
    }

    .area-user-link {
        display: flex;
        text-decoration: none;
        transition: background-color 0.3s ease-in-out;
        color: white;
        padding: 10px;
        font: normal 10pt arial;
        display: flex;
        border-bottom: 1px solid rgba(128, 128, 128, 0.514);
    }

    .area-user-link.active{
        background-color: rgba(255, 103, 103, 0.699);
        margin-bottom: 5px;
        border-end-end-radius: 8px;
        border-end-start-radius: 8px;
    }

    .area-user-link.none {
        display: none;
    }

    .main-button {
        display: none;
    }

    .main-button {
        padding: 3px;
        margin-left: 8px;
        font: normal 8pt arial;
    }

    .nav-link {
        padding: 15px;
        margin-left: 10px;
    }

    .main-button {
        font: normal 7pt arial;
        padding: 6px;
        margin-left: 13px;
    }

    .nav-link a {
        font: bold 10pt arial;
        background-color: rgba(0, 0, 0, 0.021);
        border-radius: 5px;
    }

    .sec-header-1 img {
        width: 50px;
        height: 50px;
    }

    .header-perfil img {
        width: 40px;
        height: 40px;
    }

}

@media(max-width:450px) {
    .header-area-user {
        background-color: red;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
        width: 100%;
        height: fit-content;
        bottom: 0;
        /* Coloca o elemento na parte inferior */
        top: auto;
        /* Remove o posicionamento superior */
    }

    .area-user-link {
        display: flex;
        text-decoration: none;
        transition: background-color 0.3s ease-in-out;
        color: white;
        padding: 10px;
        font: normal 10pt arial;
        display: flex;
        border-bottom: 1px solid rgba(128, 128, 128, 0.514);
    }

    .area-user-link.none {
        display: none;
    }

    .main-button {
        display: none;
    }

    .header-name-perfil {
        font: normal 8pt arial;
        color: gray;
        display: none;
        align-items: center;
        justify-content: center;
    }

    .header-perfil img {
        width: 40px;
        height: 40px;
    }
    .sec-header-1 img {
        width: 50px;
        height: 50px;
    }
}

@media(max-width:360px) {
    .header-area-user {
        background-color: red;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        gap:25px;
        width: 100%;
        height: fit-content;
        bottom: 0;
        /* Coloca o elemento na parte inferior */
        top: auto;
        /* Remove o posicionamento superior */
    }

    .nav-link {
        padding: 5px;
        margin-left: 4px;
    }

    .nav-link a {
        font: bold 4pt arial;
        background-color: rgba(0, 0, 0, 0.021);
        border-radius: 5px;
    }
    .sec-header-1 img {
        width: 50px;
        height: 50px;
    }
}

@media(max-width:280px) {
    .area-user-link .link-item {
        margin-left: unset;
        display: flex;
        justify-content: end;
        align-items: center;
        width: 100%;
        font-size: 18px;

    }
}

@media(max-width:230px) {
    .header-area-user {
        background-color: red;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0px;
        width: 100%;
        height: fit-content;
        bottom: 0;
        /* Coloca o elemento na parte inferior */
        top: auto;
        /* Remove o posicionamento superior */
    }

    .nav-link {
        padding: 5px;
        margin-left: 4px;
    }

    .nav-link a {
        font: bold 4pt arial;
        background-color: rgba(0, 0, 0, 0.021);
        border-radius: 5px;
    }

    .area-user-link .link-item {
        margin-left: unset;
        display: flex;
        justify-content: end;
        align-items: center;
        width: 100%;
        font-size: 16px;

    }
}

@media(max-width:210px) {
    .header-area-user {
        background-color: red;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0px;
        width: 100%;
        height: fit-content;
        bottom: 0;
        z-index: 1000;
        top: auto;
        /* Remove o posicionamento superior */
    }

    .nav-link {
        padding: 5px;
        margin-left: 4px;
    }

    .nav-link a {
        font: bold 4pt arial;
        background-color: rgba(0, 0, 0, 0.021);
        border-radius: 5px;
    }

    .area-user-link .link-item {
        margin-left: unset;
        display: flex;
        justify-content: end;
        align-items: center;
        width: 100%;
        font-size: 14px;
    }
}
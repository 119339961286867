.menu-element-title-style-top-area {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d3d2d27a;
}

.menu-element-title-style-top-area img{
    width: 90px;
    height: 90px;
}

.menu-element-title-style-top-area h1{
    color: #e00000;
    background-color: rgba(255, 255, 255, 0);
}

.menu-items-bottom-area{
    width: 100%;
    background-color: white;
    height: 80%;
}
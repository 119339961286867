.full-matriculasadmin {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-right: 15px;
    border-radius: 10px;
}

.matriculasadmin-elements {
    background-color: rgba(122, 122, 122, 0.164);
    width: 65%;
    height: 100%;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.matriculasadmin-elements a {
    text-decoration: none;
}

.matriculasadmin-element {
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    border-bottom: solid 6px rgba(255, 0, 0, 0.904);
    text-decoration: none;
    color: rgb(83, 75, 75);
    margin-top: 10px;
    transition: 1s;
}

.matriculasadmin-element a {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: white;
    text-decoration: none;
    color: rgb(83, 75, 75);
    transition: 1s;
}

.matriculasadmin-element a:hover {
    background-color: rgba(122, 122, 122, 0.329);
}

.matriculasadmin-sec-1 {
    font: bold 30pt arial;
    margin-left: 20px;
    margin-right: 20px;
}

.matriculasadmin-sec-2 {
    font: bold 12pt arial;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.matriculasadmin-sec-3 {
    font: bold 12pt arial;
    width: 10%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 1s;
}

.matriculasadmin-sec-3:hover {
    background-color: rgba(255, 186, 186, 0.719);
}


.show-items-matricula {
    width: 100%;
    display: flex;
    justify-content: start;
    margin-top: 10px;
}

.show-items-matricula button {
    border: 0;
    font: bold 13pt arial;
    color: rgba(0, 0, 0, 0.712);
    transition: 1s;
    margin-left: 30px;
}

.show-items-matricula button:hover {
    color: rgb(0, 0, 0);
}

@media (max-width:900px) {
    .matriculasadmin-elements {
        width: 55%;
    }
}

@media (max-width:800px) {
    .matriculasadmin-elements {
        width: 70%;
    }
}

@media (max-width:600px) {
    .matriculasadmin-elements {
        width: 90%;
    }

    .matriculasadmin-sec-2 {
        font: bold 11pt arial;
    }
}

@media (max-width:500px) {

    .matriculasadmin-elements {
        width: 100%;
    }

    .matriculasadmin-sec-2 {
        font: bold 10pt arial;
        padding: 10px;
    }
}

@media (max-width:450px) {
    .full-matriculasadmin {
        width: 100%;
        margin-bottom: 30px;
    }
}

@media (max-width:400px) {
    .matriculasadmin-elements {
        width: 100%;
    }

    .matriculasadmin-sec-1 {
        font: bold 22pt arial;
    }

    .matriculasadmin-sec-2 {
        font: bold 8pt arial;
        padding: 5px;
    }
}

@media (max-width:300px) {
    .matriculasadmin-elements {
        width: 100%;
    }

    .matriculasadmin-element {
        flex-direction: column;
    }

    .matriculasadmin-sec-1 {
        font: bold 19pt arial;
        padding: 5px;
    }

    .matriculasadmin-sec-2 {
        font: bold 6pt arial;
        padding: 5px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

.all {
    position: absolute;
    background-color: #ec3237;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.formLogin {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 30px;
}

.formLogin .logo {
    width: 150px;
    height: 150px;
}

.formLogin form {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.formLogin form div {
    width: 100%;
    gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    text-align: center;
}

.formLogin form div label {
    color: white;
    font-weight: 600;
    font-size: 16px;
    font-family: "Quicksand", sans-serif;
}

.formLogin form div input {
    border: 1px solid #ddd;
    padding: 10px;
    background-color: #fff;
    margin: 5px 0;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 97%;
}

.formLogin form div input:focus {
    outline: none; 
}

.formLogin form div select {
    border: 1px solid #ddd;
    padding: 10px;
    background-color: #fff;
    margin: 5px 0;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 98%;
}

.formLogin form div select:focus {
    outline: none; 
}

.formLogin form div select option{
    border: 1px solid #ddd;
    padding: 10px;
    background-color: #fff;
    margin: 5px 0;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 98%;
}

.formLogin form button{
    border: none;
    width: 98%;
    margin-top: 20px;
    background-color: #ffdd58;
    color: white;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: 1s;
} 

.formLogin form button:hover{
    background-color: #ffcc00;
} 

.footerLogin {
    color: rgb(224, 189, 189);
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    padding-top: 20px;
    font-size: 12px;
    font-weight: 500;
}
.footerLogin p {
    margin: 0px;
    padding: 0px;
}
.GotAcess {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    gap: 4px;
}
.GotAcess p{
    color: rgb(224, 189, 189);
    font-size: 12px;
    font-weight: 500;
    margin: 0px;
    padding: 0px;
}
.GotAcess a{
    color: white;
    text-decoration: none;
    color: #ffdd58;
    font-weight: 500;
    font-size: 15px;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
    transition: 1s;
}

.GotAcess a:hover{
    color: #ffd321;
}

@media (max-width:600px) {
    .formLogin {
        width: 95%;
    }
    .GotAcess {
        width: 95%;
    }
    .footerLogin {
        width: 95%;
    }
}
h1 {
  background-color: rgba(128, 128, 128, 0.116);
  color: rgb(133, 133, 133);
  font: bold 28pt arial;
  padding: 5px;
}

.App {
  text-align: center;
  background-color: #FAFAFA;
}

.button-delete {
  background-color: #e64040;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 25px;
  width: 40px;
  font: normal 7pt arial;
  margin-left: 10px;
  margin-right: 10px;
}

.button-edit {
  background-color: #919192;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 25px;
  width: 40px;
  font: normal 7pt arial;
  margin-left: 10px;
  margin-right: 10px;
}

.button {
  background-color: #919192;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 35px;
  width: 60px;
  font: normal 9pt arial;
  margin-left: 10px;
  margin-right: 10px;
}

.main-button {
  background-color: red;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 40px;
  width: 400px;
  font: normal 9pt arial;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  text-decoration: none;
  margin: 5px;
}

/* forms */
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 25rem;
  padding: 2rem;
  border: .1rem #c0c0c0 solid;
  border-radius: .5rem;
  list-style-type: none;
}

.form-container ul {
  width: 100%;
  display: 'flex';
  flex-direction: 'column';
  justify-content: center;
}

.form-container li {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.form-container li label {
  color: gray;
}

input {
  padding: 1rem;
  border: .1rem #c0c0c0 solid;
  border-radius: .5rem;
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  background-color: #dadadb8e;
}

input:focus {
  outline: none;
}

/* form opções */
.form-opinion {
  background-color: rgb(238, 238, 238);
  display: flex;
  justify-content: center;
  padding: 20px;
  border-radius: 5%;
}

.form-opinion ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.form-opinion li {
  margin: 10px;
}

.form-opinion label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.form-opinion input[type="checkbox"] {
  margin-right: 10px;
  /* Estilizando o checkbox oculto */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #696969;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  margin: 5px;
}

/* Estilizando o checkbox marcado */
.form-opinion input[type="checkbox"]:checked {
  background-color: #8f8f8f91;
}

/* Estilizando o texto ao lado do checkbox */
.form-opinion label span {
  font-size: 16px;
  color: #333;
}

/* Estilizando o campo de texto para "Outros" */
.form-opinion input[type="text"] {
  padding: 6px 10px;
  border: 2px solid #555;
  border-radius: 30px;
  outline: none;
  font-size: 16px;
  color: #333;
  margin-left: 10px;
  width: 300px;
  /* Ajuste a largura conforme necessário */
}

/* Icone de retorno */

.return-icon-portal {
  height: 50px;
  padding-top: 15px;
  padding-left: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.return-icon-portal a {
  text-decoration:none;
  font:bold 12pt arial;
  display: flex;
  justify-content:end;
  align-Items:start;
  gap:5px;
}

.return-icon-portal p {
  font: bold 12pt arial;
}

@media(max-width:700px) {
  h1 {
    background-color: rgba(128, 128, 128, 0.116);
    color: gray;
    font: bold 23pt arial;
    padding: 5px;
}
}

@media(max-width:500px) {
  .return-icon-portal {
    height: 50px;
    padding-top: 15px;
    padding-left: 20px;
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: rgba(243, 243, 243, 0.795);
  }

}
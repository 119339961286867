.financeiro-colonia-container {
    background-color: white;
    width: 80%;
    margin-top: 20px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding: 10px;
}
.financeiro-colonia-container button {
    width: 35px;
    height: 35px;
    padding: 0px;
    border-radius: 20px;
    font: bold 10pt arial;
    border: solid 1px gray;
    margin-top: 10px;
    transition: 1s;
    border: white;
}

.financeiro-colonia-container button:hover {
    background-color: white;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f2f2;
    padding: 20px;
    border-bottom: 1px solid #ccc;
}

.modal-title {
    font-size: 24px;
    margin: 0;
}

.close-button-form-colonia {
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: #333;
}

.form-financeiro-colonia {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.form-financeiro-colonia div {
    flex-basis: calc(50% - 10px);
    margin-bottom: 10px;
}

.form-financeiro-colonia label {
    font-size: 16px;
    margin-bottom: 5px;
}

.form-financeiro-colonia select,
.form-financeiro-colonia input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 15px;
}

.form-financeiro-colonia button {
    background-color: #4caf50;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.form-financeiro-colonia button:hover {
    background-color: #45a049;
}

.financial-table-container {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
}

.financial-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
}

.financial-table th,
.financial-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.financial-table th {
    background-color: #3498db;
    color: white;
}

.financial-table tr:hover {
    background-color: #f5f5f5;
}

.entry-status {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    padding: 0px;
    transition: background-color 0.3s;
}

.entry-status.paid {
    background-color: #2ecc71;
    color: white;
}

.entry-status.unpaid {
    background-color: #e74c3c;
    color: white;
}

.entry-status:hover {
    background-color: #c0392b;
}

/* Adicione essas linhas para tornar o layout mais responsivo */

/* Estilos básicos para telas menores */
@media (max-width: 768px) {
    .financeiro-colonia-container {
        width: 90%;
    }

    .form-financeiro-colonia div {
        flex-basis: 100%;
    }

    .entry-status {
        padding: 5px;
    }
}

/* Estilos para telas pequenas */
@media (max-width: 576px) {
    .financeiro-colonia-container {
        width: 100%;
        padding: 5px;
    }

    .form-financeiro-colonia div {
        flex-basis: 100%;
    }

    .form-financeiro-colonia button {
        font-size: 14px;
    }

    .financial-table th,
    .financial-table td {
        padding: 10px;
    }
}

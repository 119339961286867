.agenda-plano-aula-anual-full {
    width: 100%;
}
.agenda-plano-aula-anual-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
}
.agenda-plano-aula-anual {
    box-sizing: border-box;
    color: black;
    width: 100%; /* Ajuste conforme necessário */
    max-width: 50vh; /* Adicione um limite máximo de largura se desejar */
    padding: 10px;
    margin: 0 auto; /* Adiciona margens automáticas para centralizar */
}

.agenda-plano-aula-anual h2 {
    background-color: rgb(240, 5, 5);
    color: white;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center; /* Adicionando para centralizar horizontalmente */
    position: relative; /* Adicionando para que os elementos filhos se posicionem em relação a este */
    font:bold 14pt arial;
    padding: 10px;
}

.agenda-plano-aula-anual-options-edit {
    position: absolute;
    bottom: 0;
    right: 5px; 
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.agenda-plano-aula-anual-options-edit div{
    cursor: pointer;
    padding: 0;
    font: bold 13pt arial;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px rgba(255, 255, 255, 0.158);
    height: 100%;
    padding: 5px;
    transition: 0.8s;
}
.agenda-plano-aula-anual-options-edit div:hover{
    background-color: rgba(196, 0, 0, 0.726);
}

.agenda-plano-aula-anual p {
    background-color: rgb(197, 197, 197);
    font: bold 12pt arial;
    color: rgb(20, 16, 16);
    margin: 0;
    padding: 8px;
    border: solid 2px gray;
    white-space: pre-wrap;
    word-wrap: break-word;
}

@media(max-width:1010px) {
    .agenda-plano-aula-anual-grid {
        grid-template-columns: repeat(1, 1fr);
    }
    .agenda-plano-aula-anual-full {
        width: 95%;
    }
    .agenda-plano-aula-anual {
        box-sizing: border-box;
        color: black;
        width: 100%; /* Ajuste conforme necessário */
        max-width: 70vh; /* Adicione um limite máximo de largura se desejar */
        padding: 10px;
        margin: 0 auto; /* Adiciona margens automáticas para centralizar */
    }
}

@media(max-width:650px) {
    .agenda-plano-aula-anual-grid {
        grid-template-columns: repeat(1, 1fr);
    }
    .agenda-plano-aula-anual-full {
        width: 95%;
    }
    .agenda-plano-aula-anual {
        box-sizing: border-box;
        color: black;
        width: 100%; /* Ajuste conforme necessário */
        max-width: 350px; /* Adicione um limite máximo de largura se desejar */
        padding: 10px;
        margin: 0 auto; /* Adiciona margens automáticas para centralizar */
    }
    .agenda-plano-aula-anual h2 {
        background-color: rgb(240, 5, 5);
        color: white;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: start;
        position: relative; 
        padding: 15px;
        font: bold 12pt arial;
    }
}

@media(max-width:380px) {
    .agenda-plano-aula-anual-grid {
        grid-template-columns: repeat(1, 1fr);
    }
    .agenda-plano-aula-anual-full {
        width: 95%;
    }
    .agenda-plano-aula-anual {
        box-sizing: border-box;
        color: black;
        width: 100%; /* Ajuste conforme necessário */
        max-width: 250px; /* Adicione um limite máximo de largura se desejar */
        padding: 10px;
        margin: 0 auto; /* Adiciona margens automáticas para centralizar */
    }
    .agenda-plano-aula-anual h2 {
        background-color: rgb(240, 5, 5);
        color: white;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: start;
        position: relative; 
        padding: 15px;
        font: bold 12pt arial;
    }
    .agenda-plano-aula-anual p {
        background-color: rgb(197, 197, 197);
        font: bold 9pt arial;
        color: rgb(20, 16, 16);
        margin: 0;
        padding: 8px;
        border: solid 2px gray;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
}

@media(max-width:290px) {
    .agenda-plano-aula-anual-grid {
        grid-template-columns: repeat(1, 1fr);
    }
    .agenda-plano-aula-anual-full {
        width: 95%;
    }
    .agenda-plano-aula-anual {
        box-sizing: border-box;
        color: black;
        width: 100%; /* Ajuste conforme necessário */
        max-width: 200px; /* Adicione um limite máximo de largura se desejar */
        padding: 10px;
        margin: 0 auto; /* Adiciona margens automáticas para centralizar */
    }
    .agenda-plano-aula-anual h2 {
        background-color: rgb(240, 5, 5);
        color: white;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: start;
        position: relative; 
        padding: 15px;
        font: bold 12pt arial;
    }
    .agenda-plano-aula-anual p {
        background-color: rgb(197, 197, 197);
        font: bold 9pt arial;
        color: rgb(20, 16, 16);
        margin: 0;
        padding: 8px;
        border: solid 2px gray;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
}
.all-banner-userarea {
    display: flex;
    justify-content: center;
    align-items: start;
    height: 90vh;
}

.home-content-area {
    background-color: rgba(218, 218, 218, 0);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-userarea {
    background-color: #cfcfcf;
    width: 95%;
}

.banner-userarea img {
    margin-right: 30px;
    width: 100%;
    /* Defina o tamanho fixo desejado */
    height: 400px;
    transform: scale(1.0);
    display: block;
    margin: 0 auto;
    object-fit: cover;
}

.banner-userarea .slick-dots li button:before {
    color: rgb(223, 223, 0) !important;
    width: 20px !important;
    /* Cor dos dots, !important é utilizado para garantir que a cor seja aplicada */
}


@media (max-width:500px) {
    .all-banner-userarea {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('../../assets/fundo-branco-detalhes-amarelos.jpg');
        background-size: contain;
        height: 140vh;
    }

    .home-content-area {
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: 100%;
        margin-right: 0px;
        background-color: white;
        height: 130vh;
        margin-top: 90px;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-top: solid 5px gray;
        position: relative;
        padding-top: 70px;
    }

    .welcome-userarea {
        color: #838383;
        font: bold 14pt arial;
    }

    .banner-userarea {
        padding-top: 0px;
        width: 90%;
        background-color: white;
        transform: scale(1.00);
        border-bottom: 0.5px solid rgb(153, 153, 153);
    }

    .slick-slider {
        margin: 0 -10px;
    }

    .custom-slide {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .custom-slide img {
        width: 90%;
        max-width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border: 2px solid #ccc;
        transform: scale(1.05);
    }

    .logo-userarea {
        position: absolute;
        top: -50px;
        /* Ajuste conforme necessário para a posição desejada */
        left: 50%;
        /* Posiciona o centro do logo no meio */
        transform: translateX(-50%);
        /* Centraliza o logo horizontalmente */
        z-index: 1;
        /* Garante que o logo fique acima do conteúdo */
    }

    .logo-userarea img {
        width: 100px;
        /* Ajuste conforme necessário para o tamanho desejado */
        height: auto;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border: 2px solid #ccc;
    }

    .items-usearea {
        width: 95%;
        display: flex;
        flex-direction: column;
        margin-top: 80px;
    }

    .items-usearea h4 {
        color: #333;
        font: bold 13pt arial;
        border-left: solid 10pt rgb(221, 28, 28);
        padding-left: 5px;
        text-align: start;
    }

    .items-usearea-list {
        margin: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Cria duas colunas com largura igual (1fr) */
        gap: 10px;
        /* Espaçamento entre as colunas */
    }

    .items-usearea-1 {
        width: 150px;
        height: 90px;
        background: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        color: #333;
        font: normal 11pt arial;
        padding: 4px;
        cursor: pointer;
    }

    .items-usearea-1-icon {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: start;
        align-items: center;
        padding-left: 5px;
        font: bold 17pt arial;
    }

    .items-usearea-1-title {
        width: 100%;
        height: 50%;
        text-align: start;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-left: 5px;
    }
    .items-usearea-list-redes {
        margin: 15px;
        display: flex;
        
    }
    .items-usearea-list-redes img {
        width: 40px;
        padding: 5px;
        border-radius: 8px;
        transition: 1s;
    }
    .items-usearea-list-redes img {
        width: 40px;
        padding: 5px;
        border-radius: 2px;
    }
    .items-usearea-list-redes a {
        margin: 5px;
    }
    .items-usearea-list-redes img:hover {
        background-color: #c2c2c2;
        
    }

}
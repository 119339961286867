.agendamain-fullscreen {
    height: 100%;
    background-color: #FAFAFA;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
}

.agendamain-acess-full {
    margin-top: 10px;
    background-color: white;
    border-radius: 10px;
    width: 80%;
    height:90%;
    display: flex;
    justify-content: center;
}

.agendamain-acess {
    margin-top: 30px;
    width: min-content;
    height: min-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
}

.agendamain-item {
    height: 160px;
    width: 170px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.name-main-agendamain-colonia {
    font: bold 12pt arial;
    color: gray;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: start;
    margin-top: 5px;
}

.icon-main-agendamain-colonia {
    font: bold 45pt arial;
    height: 50%; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.agendamain-item:hover {
    background-color: rgba(128, 128, 128, 0.295);
}

@media(max-width:700px) {
    .agendamain-acess-full {
        width: 90%;
    }

    .agendamain-item {
        height: 130px;
        width: 140px;
    }

    .name-main-agendamain-colonia {
        font: bold 10pt arial;
    }

    .icon-main-agendamain-colonia {
        font: bold 40pt arial;
        height: 50%; 
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
    }
}

@media(max-width:500px) {
    .agendamain-fullscreen {
        padding-bottom: 100px;
        height: 140vh;
    }
    .agendamain-acess-full {
        width: 100%;
    }

    .name-main-agendamain-colonia {
        font: bold 8pt arial;
    }

    .icon-main-agendamain-colonia {
        font: bold 30pt arial;
        height: 50%; 
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
    }
}
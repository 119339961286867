.banner {
    background-image: url('../assets/frntecolegio5.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position:center;
    height: 100vh;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.shadow {
    background-color: rgba(0, 0, 0, 0.11);
    display: flex;
    justify-content: end;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px;
}

.sec-banner-2 {
    width: 100%;
}

@media(max-width:950px) { 
    .sec-banner-1 {
        width: 90%;
    }

}
@media(max-width:700px) {
    .banner {
        height: 90vh;
    }
}
@media(max-width:600px) {
    .banner {
        height: 80vh;
    }
}
@media(max-width:500px) {
    .banner {
        height: 70vh;
    }
}
@media(max-width:400px) {
    .banner {
        height: 60vh;
    }
}
.ensino-screen {
    background-image: url('../assets/Imagem do WhatsApp de 2023-08-16 à(s) 19.56.51.jpg');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.199);
}

.ensino-screen p {
    width: fit-content;
    font: bold 35pt arial;
    color: red;
}

/* item grid */
.grid-ensino {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin: 20pt;
}

.grid-item-ensino {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    width: 100%;
    height: 100%;
}

.item-ensino {
    position: relative;
    overflow: hidden;
}

.item-ensino-element-part {
    font: normal 10pt arial;
    background-color: white;
    height: 350px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.item-ensino p {
    color: rgb(100, 100, 100);
    width: 80%;
    font: normal 10pt arial;
    text-align: justify;
}

.item-ensino h4 {
    font: normal 15pt arial;
}

.item-ensino .item-img {
    width: 100%;
    position: relative;
    transition: transform 1s;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.item-ensino img {
    top: 0;
    left: 0;
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ajusta a imagem para caber dentro do elemento mantendo a proporção */
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.item-ensino:hover .item-img {
    transform: scale(1.1);
    /* Aumenta o tamanho da imagem em 10% quando o mouse está sobre o elemento pai */
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

@media(max-width:900px) {
    .grid-ensino {
        gap: 25px;
    }

    .item-ensino p {
        color: rgb(100, 100, 100);
        width: 80%;
        font: normal 10pt arial;
        text-align: justify;
    }

    .item-ensino-element-part {
        padding: 5px;
        height: 450px;
    }

    .item-ensino h4 {
        font: normal 15pt arial;
    }
}

@media(max-width:800px) {

    .grid-ensino {
        width: 90%;
        grid-template-columns: repeat(1, 1fr);
        gap: 25px;
        padding: 10px;
    }

    .item-ensino-element-part {
        padding: 5px;
        height: 400px;
    }

    .item-ensino h4 {
        font: normal 17pt arial;
        margin-top: 10px;
    }

    .item-ensino p {
        width: 80%;
        font: normal 12pt arial;
    }
}

@media(max-width:600px) {

    .grid-ensino {
        width: 90%;
        grid-template-columns: repeat(1, 1fr);
        gap: 25px;
        padding: 20px;
    }

    .item-ensino h4 {
        font: normal 14pt arial;
        margin-top: 10px;
    }

    .item-ensino p {
        width: 80%;
        font: normal 10pt arial;
    }
}

@media(max-width:400px) {

    .grid-ensino {
        width: 90%;
        grid-template-columns: repeat(1, 1fr);
        gap: 25px;
    }

    .item-ensino h4 {
        font: normal 12pt arial;
        margin-top: 10px;
    }

    .item-ensino p {
        width: 90%;
        font: normal 8pt arial;
    }
}

@media(max-width:300px) {

    .grid-ensino {
        width: 90%;
        grid-template-columns: repeat(1, 1fr);
        gap: 25px;
    }

    .item-ensino h4 {
        font: normal 10pt arial;
        margin-top: 10px;
    }

    .item-ensino p {
        width: 90%;
        font: normal 7pt arial;
    }
}
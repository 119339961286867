/* footer.css */

.footer {
    background-color: #ff0000de;
    /* Vermelho */
    color: white;
    padding: 20px;
    text-align: center;
}

.footer-icons {
    margin-bottom: 20px;
}

.icon {
    font-size: 24px;
    margin: 0 10px;
    color: white;
    text-decoration: none;
}

.icon:hover {
    color: #ffc107;
    /* Amarelo ao passar o mouse */
}

.footer-text {
    font-size: 14px;
}
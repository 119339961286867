.full-matriculado-detail {
    background: white;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button-matriculado-detail {
    width: fit-content;
    background-color: rgba(255, 0, 0, 0.801);
    padding: 9px;
    border: none;
    border-radius: 20px;
    color: white;
    font: bold 12pt arial;
    transition: 1s;
}

.button-matriculado-detail:hover {
    background-color: red;
    color: rgb(226, 226, 226);
}

.grid-container-1 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    padding: 10px;
    justify-content: center;
    align-items:start;
}

.grid-container-2 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-color: rgba(180, 180, 180, 0.336);
}

.grid-container {
    background-color: rgba(214, 214, 214, 0.808);
    width: 100%;
    height: max-content;
}

.grid-container h4 {
    width: 100%;
    background-color: rgba(255, 0, 0, 0.747);
    padding: 3px;
    color: white ;
}

.grid-item {
    padding: 10px;
    text-decoration: none;
    font: bold 11pt arial;
    color: rgba(36, 34, 34, 0.815);
    border-radius: 5px;
    background: linear-gradient(to right, rgba(182, 181, 181, 0.76), rgba(179, 179, 179, 0.61));
    display: flex;
    flex-direction: column;
}

.grid-item p {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    font: bold 13pt arial;
}

.grid-item p:nth-child(even) {
    background-color: rgba(88, 88, 88, 0.274);
}

@media (max-width: 1500px) {
    .grid-container-1 {
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 700px) {
    .full-matriculado-detail {
        background: white;
        margin-top: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 20px;
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 0px;
    }

    .grid-container-1 {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        padding: 10px;
        justify-content: center;
        align-items: center;
    }

    .grid-container-2 {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        padding: 10px;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 500px) {
    .grid-container-1 {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        padding: 10px;
        justify-content: flex-start;
        align-items: center;
    }
    .grid-item p {
        font: bold 11pt arial;
    }
}
.agenda-atividade-main-acess-full {
    margin-top: 10px;
    background-color: white;
    border-radius: 10px;
    width: 80%;
    display: flex;
    justify-content: center;
}

.turma-list {
    width: 80%;
    list-style: none;
    padding: 0;
    margin:40px;
}

.turma-list li {
    width: 100%;
    margin-bottom: 20px;
}

.turma-list a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #eeeeee; /* Link background color */
    border-radius: 8px; /* Rounded corners */
    transition: background-color 0.5s ease; /* Smooth transition on hover */
}

.turma-list a:hover {
    color: #fff;
    background-color: rgba(255, 48, 48, 0.897); /* Hover background color */
}

.icon {
    margin-right: 10px;
    font-size: 24px;
    color: #e4a226;
}

@media( max-width:900px) {
    .agenda-atividade-main-acess-full {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }
    .turma-list {
        width: 80%;
        margin:30px;
    }
}

@media( max-width:500px) {
    .agenda-atividade-main-acess-full {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }
}
/* Admin Area */
.admin-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.product-list {
    width: 80%;
    height: 100%;
}

.table {
    width: 100%;
    font: normal 8pt arial;
}

.table th,
.table td {
    width: 10%;
}

th {
    text-align: left;
}

tbody>tr:nth-child(odd) {
    background-color: #f0f0f0;
}


@media (max-width: 950px) {
    .admin-area {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100vh;
    }

    .product-list {
        width: 60%;
        height: 60%;
    }

    .table {
        background-color: green;
        width: 700px;
        height: 100%;
    }
}

@media (max-width: 650px) {
    .product-list {
        width: 400px;
        height: 400px;
    }

    .table th,
    .table td {
        width: 10%;
    }
}


@media (max-width: 500px) {
    .product-list {
        width: 300px;
    }

    .table th,
    .table td {
        width: 10%;
    }
}